import React from "react";
import WorkContainer from "./WorkContainer";
import hardwareImage from "../../static/images/reactHardware.jpg";

import frame from "../../static/images/cShell.jpg";
import equalizer from "../../static/images/equalizer.jpg";
import terminal from "../../static/images/terminal.jpg";
import ims from "../../static/images/ims.jpg";

export default function WorkDisplay() {
	return (
		<div className="dark:border-l dark:border-r dark:border-teal-400 border-l border-r border-slate-300 w-screen">
			<div className="pb-64 backdrop-blur-3xl pt-16 px-4 w-full introBackground dark:bg-zinc-950">
				<div className="flex justify-center ">
					<p className="text-3xl sm:text-5xl lg:text-6xl text-center mb-36 font-bebas projectsShadow w-fit py-2 px-6  ">
						Featured Projects
					</p>
				</div>
				<div>
					<WorkContainer
						title="FRAME"
						description="Unix command-line tool for setting up and managing C projects. Currently implementing an automatic file-watcher to apply uniform coding style guidelines using custom data structures."
						link="https://github.com/evanamedi/Frame"
						picture={frame}
						alt="placeholder"
					/>
				</div>
				<div className="mt-36">
					<WorkContainer
						title="UNWRAPPED"
						description="An intuitive web application for analyzing extended Spotify streaming history. Current implementation provides client side logic for analysis. Other versions include server-side analysis, and a desktop application. This application does not use the Spotify API for retrieving user data. This is due to the limitations and restrictions of accessing full streaming history data. Instead users need to request extended history directly from Spotify."
						link="https://github.com/evanamedi/unwrappedReact"
						demoLink={"https://unwrapped.evanamedi.com/"}
						picture={equalizer}
						alt="placeholder"
					/>
				</div>
				<div className="mt-36">
					<WorkContainer
						title="REACT HARDWARE"
						description="React Hardware is a React component library designed 
					to provide developers with a robust set of modular front-end components. 
					These components are designed to be easily integrated and customized to fit a 
					wide range of web applications. On the demo site you will find the JSX code and accompanying CSS (if applicable) that can be live tested and modified on the site."
						link="https://github.com/evanamedi/React-Hardware"
						demoLink={"https://react-hardware.evanamedi.com/"}
						picture={hardwareImage}
						alt="man hand building a webpage with tools"
					/>
				</div>
				<div className="mt-36">
					<WorkContainer
						title="WEB TERMINAL"
						description="A web-based CLI management system that allows users to start, stop, and manage Docker containers via WebSocket connections. The application is built using Node.js and Express, with MongoDB as the database for user authentication and session management."
						link="https://github.com/evanamedi/Ubuntu-Web-CLI"
						picture={terminal}
						alt="placeholder"
					/>
				</div>
				<div className="mt-36">
					<WorkContainer
						title="IMS"
						description="This Inventory Management System is a web-based application designed to manage various aspects of inventory operations, including suppliers, products, customers, orders, and sales. The application is built using the Flask web framework and follows a modular structure to maintain separation of concerns and enhance scalability. The system leverages RESTful APIs to handle CRUD operations and supports real-time data interaction (MySql) through a dynamic front-end interface."
						link="https://github.com/evanamedi/ims"
						picture={ims}
						alt="placeholder"
					/>
				</div>
			</div>
		</div>
	);
}
