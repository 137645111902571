import React from "react";
import { Link } from "react-router-dom";

export default function LetsWork() {
	return (
		<div className="rounding-b  py-56  border-b border-l border-r border-slate-300 dark:bg-zinc-950 dark:border-teal-400 w-full  dark:border-opacity-100 flex justify-center">
			<div className="flex flex-col justify-center items-center letWorkContainer">
				<div className="letsWords">
					<p className="text-5xl text-white font-bebas ">
						Let's Work <span className="letsWorkTogether">Together</span>
					</p>
				</div>
				<div className="letsWords">
					<p className=" text-xl text-white ">
						<Link className="learnMore" to="/about">
							{`Learn more`}
						</Link>
						{` about me`}
					</p>
				</div>
			</div>
		</div>
	);
}
